import React, { useEffect, useState } from "react"
import { css } from "@emotion/core"
import { connect } from "react-redux"
import { navigate } from "gatsby"
import { useIntl } from "react-intl"

import {
  LayoutQuoting,
  Title,
  SectionCenter,
  PlanDisplayCard,
  PrimaryButton,
  SecondaryButton,
  GoBack,
  PaymentInfoCard,
} from "../../components"
import { getCurrentPath } from "../../redux/actions"
import { validPaymentInfo } from "../../utils/helpers"

const PaymentInfo = ({
  location,
  getCurrentPath,
  selectedPlan,
  canProceedFromUI,
}) => {
  const intl = useIntl()
  const [isValidPlan, setIsValidPlan] = useState(true)

  useEffect(() => {
    getCurrentPath(location.pathname)
    setIsValidPlan(selectedPlan !== null)
  }, [getCurrentPath, location.pathname])

  return (
    <LayoutQuoting>
      <section
        css={css`
          padding: 5rem 0;
        `}
      >
        {isValidPlan ? (
          <>
            <Title
              title={intl.formatMessage({ id: "purchase.title" })}
              fontColor="var(--clr-primary-300)"
            />
            <SectionCenter>
              <div
                css={css`
                  display: grid;
                  row-gap: 1.5rem;
                `}
              >
                {selectedPlan && <PlanDisplayCard />}
                <PaymentInfoCard />
              </div>
              <div
                css={css`
                  display: flex;
                  justify-content: center;
                  margin-top: 4rem;
                `}
              >
                <PrimaryButton
                  textSize="0.875rem"
                  textTransform="capitalize"
                  maxWidth="none"
                  margin="0 4rem 0 0"
                  onClick={() => navigate("/get-quote-purchase/payment")}
                  disabled={!validPaymentInfo() || !canProceedFromUI}
                >
                  {intl.formatMessage({ id: "btn.continue" })}
                </PrimaryButton>
                <SecondaryButton
                  buttonColor="var(--clr-neutral-400)"
                  textSize="0.875rem"
                  margin="none"
                  onClick={() => navigate("/get-quote-purchase/review")}
                >
                  {intl.formatMessage({ id: "btn.goback" })}
                </SecondaryButton>
              </div>
            </SectionCenter>
          </>
        ) : (
          <GoBack
            backToLink="/get-quote-policies"
            notFilledOut={intl.formatMessage({ id: "redirect.missingItem1" })}
            text={intl.formatMessage({ id: "redirect.text1" })}
            actionText={intl.formatMessage({ id: "redirect.action1" })}
          />
        )}
      </section>
    </LayoutQuoting>
  )
}

const mapStateToProps = ({ selectedPlan, canProceedFromUI }) => ({
  selectedPlan,
  canProceedFromUI,
})

export default connect(mapStateToProps, { getCurrentPath })(PaymentInfo)
